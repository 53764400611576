@font-face {
  font-family: "hmnormal";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/hmsans_regular.ttf") format("truetype");
}

.App {
  text-align: center;
  font-family: 'hmnormal', hmnormal; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: 'flex';
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.headerName{
  margin-left:15px;
}

.customHeaderContainer{
  display: flex;
  flex: 1;
  background: #f9f6f6;
  color: #000; 
  padding-top:10px;
  padding-bottom:10px;
  border-bottom: 1px solid rgb(189, 186, 186);
}
.headerColumns{
  display: flex;
  flex:1;
}
.sidebar-first{
  width: 70%;
  order: 1;
  align-items: left;
  text-align: left;
  padding:20px 20px 0px 0px;
}
.sidebar-second{ 
  width: 30%;
  order: 2;
  align-items: right;
  text-align: right;
  padding:20px 20px 0px 0px;
}
.leftSideRows
{
  display: flex;
  flex:1;
  flex-direction: column;
}
.logoRow1{
  display: flex;
  flex: 1;
}
.logoRow2{
  display: flex;
  flex: 2;
  margin-left:70px;
  padding-bottom: 10px;
}
.navMenuSel{
  color:red !important;
  font-size: 18px;
  margin-right:10px;
}
.navMenuNormal{
  color:rgb(22, 21, 21) !important;
  font-size: 16px;
  margin-right:10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbarContainer{
  background-color: lightgreen;
}

.user-info-container {
  margin-top:5px;
  margin-right:15px;
   width: auto;
   text-align: right;
}
ul{
  list-style-type: none;
}
.logoutText{
  color:blue;
}
.logingButton{
  margin-top:5px;
  margin-right:5px;
}
.mainHeader{
  height:130px;
}

.logoImage{
  width:50px;
  margin:0px;
  margin-left:70px;
}
.subHeaderContainer
{
  background-color: rgb(220, 223, 224);
}
.subTitle{
  position: relative;
  margin:10px;
  margin-top:20px;
  margin-bottom:0px;
  flex-direction: row;
  vertical-align: middle;
  font-weight: bold;
  font-size:26px;
  float:left;
  margin-bottom:15px;
  color:#525259
}
.subButtonSec{
  padding-bottom:15px;
  float:right;
  margin:10px;
  flex-direction: row;
  vertical-align: middle;
}
.container {
  margin-top:10px;
  margin-left:20px !important;
  margin-right:20px !important;
  align-items: center !important;
  justify-content: center !important;
  display: 'flex' !important;
}
FORM{
  text-align: left !important;
  align-items: center !important;
  justify-content: center !important;
  display: 'flex' !important;
}
.active{
  color:red;
}
.formFieldSpace{
  margin-top: 10px;
}
.containerStyle{
  margin-top:40px;
  margin-left:5% !important;
  margin-right:5% !important;
  align-items: center !important;
  justify-content: center !important;
  display: 'flex' !important;
}
.containerStyleHome{
  margin-top:50px;
  margin-left:5% !important;
  margin-right:5% !important;
  align-items: center !important;
  justify-content: center !important;
  display: 'flex' !important;
}
.homeImageContainer{
  width:100%;
}
.homeImageStyle{
  width:100%;
  max-height: 500px !important;
  align-items: center;
  text-align: center;
  margin-top:20px;
  padding:10px;
  margin-bottom:20px;
  opacity: 0.8;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  align-items: center !important;
  justify-content: center !important;
}
.imageCellStyle{
  position:relative;
  top: 0;
  left: 0;
  align-items: center !important;
  justify-content: center !important;
}
.cornerImageStyle{
  width:30px;
  height:30px;
  position:absolute;
  top: 10px;
  left: 0;
}

.pagination-bar2{
  position: absolute;
  margin-top:55px;
  margin-left:-45px;
  flex-direction: row;
  vertical-align: left;
  font-weight: bold;
  font-size:26px;
  float:left;
  margin-bottom:35px;
  color:#525259
}
.pagination-bar{
  position:relative;
  flex-direction: row;
  vertical-align: left;
  float:left;
  margin-left:-30px;
  margin-top:15px;
}


/* Search Devices page */
.searchBoxContainer{
  border:0px solid red;
}
.searchBox{
  border:0px solid red;
  text-align:left;
}
.searchBoxLabel{
  font-weight: bold;
  font-size:18px;
}
.storeIdBox{
  margin-top:10px;
  margin-bottom:10px;
  padding: 5px;
  width:250px;
}
.searchButton{
  margin-top:0px !important;
  padding:9px 20px 9px 20px;
  margin-left:-10px;
  background-color: #171817;
  color:rgb(214, 204, 204);
  border:1px solid gray;
  width:110px;
  font-weight: bold;
  text-align: center;
}
.subTitleMore
{
  float:left;
}
.tableHeader{
  background-color: rgb(249, 231, 224);
  border: 1px solid  rgb(159, 158, 158) !important;
}
.tableHeader th{
  width:220px !important;
  padding:12px !important;
  border: 1px solid  rgb(159, 158, 158) !important;
}
.tableRow{
  background-color: #ffffff !important;
  border: 1px solid  rgb(159, 158, 158) !important;
}
.tableRow:hover{
  background-color: #ffffff !important;
}
table{
  background-color: #ffffff !important;
}
.tableRow td{
  background-color: #ffffff !important;
  padding:12px !important;
  --bs-table-accent-bg:#ffffff !important;
  border: 1px solid  rgb(159, 158, 158) !important;
}
.errorMessage{
  font-weight:bold;
  font-size: 15px;
  margin:20px;
  color:rgb(100, 39, 39)
}
.textGreen{
  color:rgb(14, 165, 14);
  font-weight: bold;
}
.textRed{
  color:rgb(187, 19, 19);
  font-weight: bold;
}
.unblockBtn{
  margin-top:0px !important;
  padding:9px 20px 9px 20px;
  background-color: #171817;
  color:rgb(214, 204, 204);
  border:0px solid gray;
  width:110px;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}
.unblockBtnDisabled{
  margin-top:0px !important;
  padding:9px 20px 9px 20px;
  background-color: #c8c9c8;
  color:rgb(121, 119, 119);
  border:0px solid gray;
  width:110px;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}
.btnNormal{
  margin-top:0px !important;
  padding:9px 20px 9px 20px;
  background-color: #fafbfa;
  color:rgb(59, 57, 57);
  border:1px solid gray;
  min-width:120px;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  margin-right:5px;
  height: 40px;
  cursor: pointer;
  margin-left:10px;
} 
.margin10px{
  margin:10px;
}
.btnNormal.active{
  background-color: #121312;
  color:#fafbfa;;
}
.btnNormalBkp{
  margin-top:0px !important;
  padding:9px 10px 9px 10px;
  background-color: #222222;
  color:#FFFFFF;
  border:0px solid gray;
  min-width:120px;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  margin-right:5px;
  min-height: 40px;
  cursor: pointer;
} 
.btnNegative{
  margin-top:0px !important;
  padding:9px 20px 9px 20px;
  background-color: #fafbfa;
  color:rgb(59, 57, 57);
  border:1px solid gray;
  min-width:90px;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  margin-right:5px;
  height: 40px;
  cursor: pointer;
}
.btnNegativeBkp{
  margin-top:0px !important;
  padding:9px 20px 9px 20px;
  background-color: #d0310d;
  color:rgb(242, 236, 236);
  border:0px solid gray;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  height: 40px;
  cursor: pointer;
}
.btnNormal2{
    margin-top:0px !important;
  padding:9px 20px 9px 20px;
  background-color: #777104;
  color:rgb(242, 236, 236);
  border:0px solid gray;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}
.btnNormal3{
  margin-top:0px !important;
  padding:9px 20px 9px 20px;
  background-color: #458fee;
  color:rgb(242, 236, 236);
  border:0px solid gray;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  height: 40px;
}

/** Model dialog style **/
.ModalStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  border:0px solid red;
  padding-top:100px;
}
.closeIconStyle{
  width:20px;
  cursor: pointer;
}
.closeIconStyle:hover{
  width:25px;
  cursor: pointer;
}
.closeIconStyle2{
  width:20px;
  cursor: pointer;
}
.loadingDiv{
  padding-top:50px;
  padding-left:20px;
  padding-right:20px;
  text-align: center;
}
.loadingGif{
  width:50px;
  padding-left:10px;
  padding-right:10px;
}
.loadingMesage{
  color:brown;
  font-weight: bold;
  font-size:15px;
}
.modelHeaderBG{
  background-color: rgb(249, 231, 224);
}


.modal-dialog-style {
     max-width: 80% !important; 
     margin-right: auto !important; 
     margin-left: auto !important; 
     text-align: center !important; 
     display:flex !important;
     justify-content: center !important; 
     align-items: center !important; 
     border: 1px solid red;
}

.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,-50px);
}
@media (min-width: 576px){
.modal-dialog {
  max-width: 60% !important; 
  margin-right: auto !important; 
  margin-left: auto !important; 
  text-align: center;
  display: flex;
}  
}
.formInnerStyle td
{
  padding:5px 10px 5px 10px;
  width:50%;
}

.customLinkStyle{
  color:#000000;
  font-size:17px;
  text-decoration:underline;
  cursor: pointer;
}
.innerTable{
  width:100%;
  border:0px solid red;
}
.innerTable tr{
  border-bottom:1px solid lightgray;
}
.reportHeader{
  font-weight: bold;
  font-size: 18px;
  margin-top:10px;
  margin-bottom:10px;
}
.statusIconStyle{
  width:40px;
  cursor: pointer;
}
.statusIconStyle:hover{
  width:40px;
  cursor: pointer;
}
.statusIcon{
  width:30px;
  height:30px;
  margin:10px;
  border-radius: 50%;
  cursor: pointer;
}
.statusIcon.red{
  background-color: red;;
}
.statusIcon.green{
  background-color: green;;
}
.statusIcon.yellow{
  background-color: yellow;;
}
.statusIcon.orange{
  background-color: orange;;
}
.hideme{
  display: none;
}
.textColorGreen{
  color:green !important;
  font-weight: bold;
}
.textColorRed{
  color:red !important; 
  font-weight: bold;
}

/* Config error box style */
.errHeader{
  background-color: rgb(248, 248, 243);
  color:#7b0b0b;
  font-weight: bold;
  font-size: 17px;
  width:100%;
  padding-left:10px;
  padding-right:10%;
  text-align: left;
}
.errContent{
  background-color: rgb(248, 248, 243);;
  color:#222222;
  font-weight: bold;
  font-size: 14px;
  width:100%;
  padding:10px;
  text-align: left;
  text-align: center;
}
.errContent ul li{
  list-style-type: none;
  margin-bottom:10px;
}
.size16{
  font-size:25px;
}

/* custom radio button */
.question-box__question {
  position: relative;
}
.question-box__question:not(:last-child) {
  margin-bottom: 10px;
}

.question__input {
  position: absolute;
  left: -9999px;
}

.question__label {
  position: relative;
  line-height: 40px;
  border: 0px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 10px 5px 50px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  font-size: 20px;
}
.question__label:hover {
  border-color: #cc3300;
}
.question__label:before, .question__label:after {
  position: absolute;
  content: "";
}
.question__label:before {
  top: 12px;
  left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #ccc;
  transition: all 0.15s ease-in-out;
}

.question__input:checked + .question__label:before {
  background-color: #cc3300;
  box-shadow: 0 0 0 0;
}
.question__input:checked + .question__label:after {
  top: 20px;
  left: 17px;
  width: 10px;
  height: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}

.noteBoxHandling{
  font-size: 15px;
  font-weight:normal;
  list-style-type: none;
}

.tableRow_no{
  background-color: #ffffff !important;
  border: 0px solid  rgb(159, 158, 158) !important;
}
.tableRow_no:hover{
  background-color: #a12020 !important;
}
.tableRow_no td{
  background-color: #ffffff !important;
  padding:0px !important;
  --bs-table-accent-bg:#ffffff !important;
  border: 0px solid  rgb(159, 158, 158) !important;
}

.form-label{
  margin-top:10px;
  font-weight:bold;
}

/* BEST - JS calculated height */
.list-group-item {
  .card-title {
    cursor: pointer;
    margin-top:15px;
    margin-bottom:10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }
  .card-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;
  }
  .card-body {
    border: none !important; 
  }
  .helph5 {
    margin-top: 5px !important;
  }
}

.collapsecontent {
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.2));

}

.OModelContainer{
  background-color: white;
  padding:0px;
  border: 1px solid black;
  min-width:220px;
  border-radius: 7px 7px 0px 0px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 999;
  margin-top:5px;
}

.OModelContainer:before {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
  background: #282c34;
  position: absolute;
  z-index: 998;
  top:-10px;
  border-radius: 1px 0px 0px 0px;
}
.OModelContainer div.title {
  font-size:15px;
  text-align: center;
  background-color: #282c34;
  color:wheat;
  padding: 5px;
}
.OModelContainer div.content {
  font-size:15px;
  text-align: center;
  padding: 5px;
  padding-bottom:10px;
}
.buttonOverlayBox {
  margin-top:10px;
  padding-top:5px;
}
.btnNormalOverlay {
  background-color: #fafbfa;
  color:rgb(59, 57, 57);
  border:1px solid gray;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  width:100px;
  padding:5px;
} 
